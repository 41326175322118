<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-text.general.field-name')"
      label-for="at-text-field__name-input"
    >
      <b-form-input
        id="at-text-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-text.general.enter-field-modal-name')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-text.general.field-alias')"
      aria-required="true"
    >
      <b-form-input
        v-model.trim="tempAlias"
        :state="tempAlias === null ? null : !aliasState"
        :placeholder="$t('fields.codex-field-text.general.enter-field-alias-name')"
        trim
      />
      <b-form-invalid-feedback v-if="!nameState.isValid">
        {{ $t('fields.general.validation.alias-invalid') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="nameState.isReserved">
        {{ $t('fields.general.validation.alias-is-reserved') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!nameState.isUnique">
        {{ $t('fields.general.validation.alias-is-used') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-text.general.description')"
      label-for="number-field__description"
    >
      <b-form-textarea
        id="at-text-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-text.general.enter-description')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-group :label="$t('fields.codex-field-text.general.display')">
      <b-form-radio-group v-model="valueType">
        <b-form-radio
          :value="VALUE_TYPES.SINGLE"
        >
          {{ $t('fields.codex-field-text.general.single') }}
        </b-form-radio>
        <b-form-radio
          :value="VALUE_TYPES.LIST"
        >
          {{ $t('fields.codex-field-text.general.list') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-button
      variant="primary"
      class="w-100"
      :disabled="aliasState"
      @click="create"
    >
      {{ $t('fields.codex-field-text.general.create') }}
    </b-button>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'description',
      'configured',
      'validation',
      'appearance',
      'type',
      'valueType',
    ]),
    aliasState() {
      return this.nameState.isReserved || !this.nameState.isUnique || !this.nameState.isValid
    },
  },
}
</script>

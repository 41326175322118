// 100MB
export const MAX_FILE_SIZE = 100 * 1024 * 1024
export const MAX_VP_UPLOAD_SIZE = 5 * 1024 * 1024 * 1024

export const LANGUAGES = Object.freeze({
  ENGLISH: 'en',
  CZECH: 'cs',
  FRENCH: 'fr',
  ALBANIAN: 'sq',
})

export const LAYOUT_BLOCKS = ['codex_row', 'codex_column', 'codex_container', 'codex_tabs', 'codex_tab']

export const MAX_PAGE_SIZE = 2147483647

export const CMI_ORGANIZATIONS = ['cmifrance', 'cmifrance-sandbox', 'cmifrance-stg']

export const VUE_APP_ENV = Object.freeze({
  PRODUCTION: 'production',
  STAGING: 'staging',
  TESTING: 'testing',
  DEVELOPMENT: 'development',
})

export const isDevelopmentOnly = process.env.VUE_APP_ENV === VUE_APP_ENV.DEVELOPMENT
export const isDevelopment = process.env.VUE_APP_ENV === VUE_APP_ENV.DEVELOPMENT || process.env.VUE_APP_ENV === VUE_APP_ENV.TESTING
export const isStaging = process.env.VUE_APP_ENV === VUE_APP_ENV.STAGING
export const isProduction = process.env.VUE_APP_ENV === VUE_APP_ENV.PRODUCTION

export const $IMAGE_SIZES = Object.freeze({
  ORIGINAL: 4,
  REGULAR_1080: 3,
  SMALL_400: 2,
  THUMBNAIL_200: 1,
})

export const ENTITY_TYPES = Object.freeze({
  ASSET: 'asset',
  AUTHOR: 'author',
  ENTRY: 'entry',
  ENTRY_VERSION: 'entry_version',
  ENTRY_SCHEDULE_VERSION: 'entry_schedule_version',
  FOLDER: 'folder',
  LABEL: 'label',
  MODEL: 'model',
  SECTION: 'section',
  SITE: 'site',
  TEAM: 'team',
  TEAM_MEMBER: 'team_member',
  VIEW: 'view',
  CONNECTION: 'connection',
  API_KEY: 'api_key',
  USER: 'user',
  LIST: 'list',
  URL: 'url',
  WEBHOOK: 'webhook',
  TAG: 'tag',
  PRESET: 'preset',
  JOB: 'job',
  PLUGIN: 'plugin',
  WORKFLOW: 'workflow',
})

export const directoryMapping = function (type) {
  switch (type.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'jfif':
    case 'png':
    case 'gif':
    case 'webp':
    case 'ico':
    case 'svg':
    case 'avif':
      return 'images'
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'mpeg':
    case 'webm':
      return 'videos'
    case 'aac':
    case 'mp3':
    case 'mid':
    case 'wav':
    case 'weba':
      return 'audios'
    case 'woff':
    case 'otf':
    case 'ttf':
    case 'ttc':
    case 'fot':
    case 'fon':
      return 'fonts'
    case 'pdf':
    case 'txt':
    case 'csv':
    case 'json':
    case 'yaml':
    case 'toml':
    case 'js':
    case 'xls':
    case 'xlsx':
    case 'xml':
      return 'files'
    case 'css':
    case 'sass':
    case 'scss':
      return 'themes'
    default:
      return 'others'
  }
}

export const suffixMapping = {
  j: 'jpg',
  je: 'jpeg',
  p: 'png',
  s: 'svg',
  w: 'web',
  wp: 'webp',
  i: 'ico',
  g: 'gif',
  t: 'tif',
  tf: 'tiff',
  m4: 'mp4',
  a: 'avi',
  m: 'mov',
  mg: 'mpeg',
  wm: 'webm',
}

export const AUTO_SAVE_STATUSES = Object.freeze({
  SAVING: 'saving',
  SAVED: 'saved',
  FAILED: 'failed',
})

export const SEARCH_TYPES = Object.freeze({
  ASSET: 'ASSET',
  AUTHOR: 'AUTHOR',
  ENTRY: 'ENTRY',
  FOLDER: 'FOLDER',
  LABEL: 'LABEL',
  MODEL: 'MODEL',
  SECTION: 'SECTION',
  SITE: 'SITE',
  TEAM: 'TEAM',
})

export const SEARCH_TYPES_MAPPING = Object.freeze({
  ASSET: 'general.search-types.asset',
  AUTHOR: 'general.search-types.author',
  ENTRY: 'general.search-types.entry',
  FOLDER: 'general.search-types.folder',
  LABEL: 'general.search-types.label',
  MODEL: 'general.search-types.model',
  SECTION: 'general.search-types.section',
  SITE: 'general.search-types.site',
  TEAM: 'general.search-types.team',
})

export const ERROR_CODES = Object.freeze({
  ADMINISTRATION: {
    OrganizationAliasIsRequired: 1600,
    OrganizationAliasAlreadyInUse: 1601,
    OrganizationNotFound: 1602,
    OrganizationNameIsRequired: 1603,
    NoAccessToCreateOrganization: 1610,
    NoAccessToEditOrganization: 1611,

    SiteAliasIsRequired: 1700,
    SiteAliasAlreadyInUse: 1701,
    SiteNotFound: 1702,
    SiteNameIsRequired: 1703,
    SiteIdIsRequired: 1704,
    SiteDomainIsNotValid: 1705,
    NoAccessToListSites: 1720,
    NoAccessToViewSite: 1721,
    NoAccessToCreateSite: 1722,
    NoAccessToEditSite: 1723,
    NoAccessToDeleteSite: 1724,

    UserInvitationsAreRequired: 1800,
    UserInvitationEmailsAreRequired: 1801,
    UserInvitationEmailIsRequired: 1802,
    UserInvitationEmailIsNotValid: 1803,
    UserInvitationIssuerIsCurrentUser: 1804,
    UserInvitationInvitedUsersAreAlreadyPartOfOrganization: 1805,
    NoAccessToListInvitations: 1806,
    NoAccessToInviteUsers: 1807,
    UserInvitationsTeamsIsRequired: 1808,
    UserInvitationsTeamsIdInvalid: 1809,

    AcceptInvitationIsRequired: 1810,
    AcceptInvitationNotFound: 1811,
    AcceptInvitationAuthenticatedUserIsRequired: 1812,
    AcceptInvitationAuthenticatedUserIsNotInvitedUser: 1813,
    AcceptInvitationHasExpired: 1814,

    UserIdIsRequired: 1820,
    UserNotFound: 1821,
    UserIsApiKey: 1822,
    UserCannotEditItselfAccessControl: 1823,
    UserIsNotAuthenticated: 1830,
    NoAccessToListUsers: 1831,
    NoAccessToViewUser: 1832,
    NoAccessToEditUser: 1833,
    NoAccessToDeleteUser: 1834,

    ApiKeyNotFound: 1840,
    ApiKeyNotRegeneratable: 1841,
    ApiKeyNotRevocable: 1842,
    ApiKeyAlreadyRevoked: 1843,
    RevokedApiKeyAreNotEditable: 1844,
    ApiKeyNameIsRequired: 1845,
    ApiKeyExpirationIsNotValid: 1846,
    NoAccessToListApiKeys: 1850,
    NoAccessToViewApiKey: 1851,
    NoAccessToCreateApiKey: 1852,
    NoAccessToEditApiKey: 1853,
    NoAccessToRegenerateApiKey: 1854,
    NoAccessToRevokeApiKey: 1855,
    NoAccessToDeleteApiKey: 1856,

    TeamAlreadyExists: 1860,
    TeamNotFound: 1861,
    PredefinedTeamsCannotBeModified: 1862,
    TeamNameIsRequired: 1863,
    TeamDescriptionIsRequired: 1864,
    TeamMembersAreNotValid: 1865,
    AccessControlIsRequired: 1866,
    ContentAccessControlRulesAreRequired: 1867,
    ContentAccessControlRulesAreNotValid: 1868,
    ContentAccessControlPermissionsAreNotValid: 1869,
    AssetsAccessControlRulesAreRequired: 1870,
    AssetsAccessControlRulesAreNotValid: 1871,
    AssetsAccessControlPermissionsAreNotValid: 1872,
    AdministrationAccessControlPermissionsAreNotValid: 1873,
    BuilderAccessControlPermissionsAreNotValid: 1874,
    PluginsAccessControlPermissionsAreNotValid: 1875,
    NoAccessToUpdateOrganizationOwnersTeams: 1879,
    NoAccessToListTeams: 1880,
    NoAccessToViewTeam: 1881,
    NoAccessToCreateTeam: 1882,
    NoAccessToEditTeam: 1883,
    NoAccessToDeleteTeam: 1884,
  },
  ASSETS: {
    // Assets & Uploads
    FileNotEmpty: 3000,
    FileExceedsLimitedSize: 3001,
    NotSupportedFile: 3002,
    AssetTypeNotEmpty: 3003,
    InvalidAssetType: 3004,
    InvalidAssetTitle: 3005,
    TagNotEmpty: 3006,
    AssetNotFound: 3007,
    InvalidAssetId: 3008,
    InvalidTitleSuffix: 3009,
    AtLeastOneAssetIsRequired: 3010,
    UploadingFileHasFailed: 3011,
    StorageConfigurationsRequired: 3012,
    InvalidStorageId: 3013,
    FileNameNotEmpty: 3014,
    ContentTypeNotEmpty: 3015,
    PathNotEmpty: 3016,
    NoAccessToDeleteAsset: 3017,
    NoAccessToEditAsset: 3018,
    NoAccessToCreateAsset: 3019,
    NoAccessToUploadAsset: 3020,
    AtLeastOneFileIsRequired: 3021,

    // Folders
    FolderNotFound: 3300,
    AllowedMaxDepthExceeded: 3301,
    FolderNameNotEmpty: 3302,
    InvalidFolderNameLength: 3303,
    InvalidFolderId: 3304,
    InvalidFolderParentId: 3305,
    DeleteFolderOptionNotEmpty: 3306,
    InvalidDeleteFolderOption: 3307,
    DeletingFolderHasFailed: 3308,
    NoAccessToDeleteFolder: 3309,
    NoAccessToEditFolder: 3310,
    NoAccessToCreateFolder: 3311,
    InvalidFolderChild: 3312,
  },
  CONTENT: {
    // Models
    InvalidModel: 2001,
    InvalidModelFieldType: 2002,
    ModelFieldAliasesAreNotUnique: 2003,
    ModelNotFound: 2004,
    ModelFieldAliasNotAllowedCharacters: 2005,
    ModelNullOrEmptyFieldAttributes: 2006,
    ModelFieldAliasMissing: 2007,
    ModelFieldNameMissing: 2008,
    InvalidModelFieldValueType: 2009,
    InvalidModelFieldRangeOperator: 2010,
    ModelFieldUndefinedRangeOperator: 2011,
    ModelVersionNotFound: 2012,
    ModelAlreadyExists: 2013,
    VersionModelIdIsRequired: 2014,
    ModelAliasIsRequired: 2015,
    ModelAliasShouldBeAlphanumerics: 2016,
    ModelAliasIsReserved: 2017,
    ModelNameIsRequired: 2018,
    ModelNameShouldBeAlphanumerics: 2019,
    ModelIconIsRequired: 2020,
    InvalidModelId: 2021,
    ModelFieldsAreRequired: 2022,
    ModelTitleFieldAliasIsRequired: 2023,
    ModelSlugFieldIsRequired: 2024,
    ModelFieldAliasIsReserved: 2025,
    ModelFieldCannotBeActiveAndDeleted: 2026,
    ModelFieldValueRangeMinIsRequiredForAtLeastOperator: 2028,
    ModelFieldValueRangeMaxIsRequiredForAtMostOperator: 2029,
    ModelFieldValueRangeMinIsRequiredForBetweenOperator: 2030,
    ModelFieldValueRangeMaxIsRequiredForBetweenOperator: 2031,
    ModelFieldValueRangeMinCannotBeGreaterThanMax: 2032,
    ModelFieldValueRangeExactlyIsRequiredForExactlyOperator: 2033,
    ModelReferencedModelDoesNotExists: 2034,
    ModelWithExternalIdAlreadyExists: 2035,
    ModelFieldTypeUndefined: 2036,
    ModelMissingRevision: 2037,
    ModelRevisionMismatch: 2038,
    ModelVersionEntryIdIsNotCorrect: 2039,
    InvalidModelFeatureMediaFieldAlias: 2040,
    ModelUrlFieldPatternsAreNotUnique: 2041,
    NoAccessToDeleteModel: 2042,
    NoAccessToCreateModel: 2043,
    NoAccessToViewModel: 2044,
    NoAccessToPublishModel: 2045,
    NoAccessToEditModel: 2046,
    NoAccessToManageModel: 2047,
    ModelEntriesCannotBeLocked: 2048,
    CircularModelMapping: 2049,
    MappedTargetModelNotFound: 2050,
    MappedSourceFieldNotFound: 2051,
    MappedTargetFieldNotFound: 2052,
    ModelFieldMappingTypeMismatch: 2053,
    ModelFieldMappingValueTypeMismatch: 2054,
    ModelMappingFromModelIdIsRequired: 2055,
    ModelMappingToModelIdIsRequired: 2056,
    ModelMappingFieldsCannotBeEmpty: 2057,
    ModelMappingAlreadyExists: 2058,
    InvalidModelMapping: 2059,
    ModelMappingNotFound: 2060,
    ModelMappingFromModelIdCannotBeChanged: 2061,
    ModelMappingFieldsCannotHaveDuplicateTargetFields: 2062,
    ModelMappingBetweenModelsNotFound: 2063,
    UnmappedModelFieldsBetweenModels: 2064,

    // Entries
    InvalidEntry: 2101,
    InvalidEntryVersion: 2102,
    EntryForbiddenSite: 2103,
    EntryNotFound: 2104,
    EntryMissingRevision: 2105,
    EntryStatusInvalidTransition: 2106,
    EntryVersionNotFound: 2107,
    InvalidEntryLabel: 2108,
    EntryPublishedAtNotSet: 2109,
    UndefinedEntryStatus: 2110,
    EntryUnpublishedAtShouldBeAfterPublishedAt: 2111,
    EntryUnpublishedAtShouldBeAfterActualDate: 2112,
    EntryTitleIsRequired: 2113,
    EntryContentContainsUndefinedFields: 2114,
    EntrySlugIsRequired: 2115,
    EntrySlugIsInvalid: 2116,
    EntrySlugMustBeUnique: 2117,
    EntryMetricsIsInvalid: 2118,
    EntryFieldValueIsNotUniqueInCollection: 2119,
    EntryReferredEntryDoesNotExists: 2120,
    EntryReferredModelDoesNotExists: 2121,
    EntryIsUsedInList: 2122,
    EntryFieldIsRequired: 2123,
    EntryFieldValueTypeIsNotList: 2124,
    EntryFieldValueTypeIsNotSingle: 2125,
    EntryFieldPredefinedValueDoesNotContainProvidedValue: 2126,
    EntryFieldValueMustBeAtLeast: 2127,
    EntryFieldValueMustBeMost: 2128,
    EntryFieldValueMustBeBetweenMinAndMax: 2129,
    EntryFieldValueMustBeExactly: 2130,
    EntryFieldValueDoesNotMatchFormat: 2131,
    EntryWithExternalIdAlreadyExists: 2132,
    EntryFieldValueTypeUndefined: 2133,
    EntryFieldValueRangeMinIsRequiredForAtLeastOperator: 2134,
    EntryFieldValueRangeMaxIsRequiredForAtMostOperator: 2135,
    EntryFieldValueRangeMinIsRequiredForBetweenOperator: 2136,
    EntryFieldValueRangeMaxIsRequiredForBetweenOperator: 2137,
    EntryFieldValueRangeMinCannotBeGreaterThanMax: 2138,
    EntryFieldValueRangeExactlyIsRequiredForExactlyOperator: 2139,
    EntrySectionIdIsInvalid: 2140,
    EntryAuthorIdIsInvalid: 2141,
    EntryInvalidReferenceModel: 2142,
    EntryForbiddenReferenceModel: 2143,
    EntryInvalidReferenceEntryId: 2144,
    EntryInvalidEntryStatus: 2146,
    EntryRevisionMismatch: 2147,
    EntryVersionEntryIdIsRequired: 2148,
    EntryVersionEntryIdIsNotCorrect: 2149,
    UrlAlreadyExists: 2150,
    DuplicateEntryUrlValues: 2151,
    UnchangedPublishedAt: 2152,
    UnchangedUnpublishedAt: 2153,
    RequiredUnpublishedAt: 2154,
    NoAccessToViewEntry: 2155,
    NoAccessToCreateEntry: 2156,
    NoAccessToEditEntryContent: 2157,
    NoAccessToEditEntryLabels: 2158,
    NoAccessToDeleteEntry: 2159,
    NoAccessToPublishEntry: 2160,
    NoAccessToScheduleEntry: 2161,
    NoAccessToUnpublishEntry: 2162,
    NoAccessToEditEntryFields: 2163,
    EntryIsLocked: 2164,
    InvalidEntryIdFormat: 2165,
    InvalidPermissionCheck: 2166,
    InvalidObjectPermissionCheck: 2167,
    InvalidEntrySectionFieldValue: 2168,
    EntryLockNotFound: 2169,

    // Author
    AuthorIdIsRequired: 2301,
    AuthorBylineIsRequired: 2302,
    AuthorStatusUndefined: 2303,
    AuthorNotFound: 2304,
    AuthorInvalidStatus: 2305,
    AuthorBiographyIsRequired: 2306,
    AuthorImageIsRequired: 2307,
    AuthorInvalidEmail: 2308,
    AuthorInvalidFacebook: 2309,
    AuthorInvalidWebsite: 2310,
    AuthorInvalidInstagram: 2311,
    AuthorInvalidTwitter: 2312,
    AuthorInvalidLinkedIn: 2313,
    AuthorInvalidTelegram: 2314,
    AuthorWithExternalIdAlreadyExists: 2315,
    LabelWithExternalIdAlreadyExists: 2316,
    NoAccessToManageAuthor: 2317,
    NoAccessToViewAuthor: 2318,

    // Label
    LabelAlreadyExists: 2401,
    LabelNameIsRequired: 2402,
    LabelInvalidName: 2403,
    LabelNotFound: 2404,
    LabelColorIsRequired: 2405,
    LabelTypeIsRequired: 2406,
    LabelTypeUndefined: 2407,
    NoAccessToManageLabel: 2408,
    NoAccessToViewLabel: 2409,

    // Section
    SectionMissingNewParentId: 2501,
    SectionParentNotFound: 2502,
    SectionSelfReference: 2503,
    SectionNotFound: 2504,
    SectionCircularReference: 2505,
    SectionBelongsToDifferentSite: 2506,
    SectionTitleIsRequired: 2507,
    SectionParentIsInvalid: 2508,
    SectionSiteIdIsRequired: 2509,
    SectionIsUsedInEntries: 2510,
    SectionWithExternalIdAlreadyExists: 2511,
    NoAccessToViewSection: 2512,
    NoAccessToCreateSection: 2513,
    NoAccessToEditSection: 2514,
    NoAccessToDeleteSection: 2515,

    // List
    InvalidEntrySchedulerDateTimeRange: 2601,
    ListNameIsRequired: 2602,
    UnscheduledListShouldHaveEntries: 2603,
    ListNotFound: 2604,
    ListTypeIsUndefined: 2605,
    ListEntryStartAtShouldBeLessThanEndAt: 2606,
    ListWithExternalIdAlreadyExists: 2607,
    ListContainsDuplicateEntries: 2608,
    NoAccessToDeleteList: 2609,
    NoAccessToCreateList: 2610,
    NoAccessToEditList: 2611,
    NoAccessToViewList: 2612,
    ListEntryEndAtShouldBeInFuture: 2613,
    ListEntryStartAtShouldBeLaterOrEqualToNow: 2614,
    ListWithAliasAlreadyExists: 2615,
    InvalidUrlRedirectStatusCode: 2616,
    ListAliasIsRequired: 2617,

    // Media
    MediaImageTypeIsNotAllowed: 2701,
    MediaPlaylistTypeIsNotAllowed: 2702,
    MediaVideoTypeIsNotAllowed: 2703,
    MediaFileTypeIsNotAllowed: 2704,

    // View
    ViewSiteIdIsRequired: 2750,
    ViewNameIsRequired: 2751,
    ViewNotFound: 2752,
    ViewTeamsIdInvalid: 2753,
    ViewTypeIsRequired: 2754,
    ViewTypeIsInvalid: 2755,
    PrivateViewCannotContainTeams: 2756,
    NoAccessToDeleteView: 2757,
    NoAccessToCreateView: 2758,
    NoAccessToEditView: 2759,
    NoAccessToViewView: 2760,

    // General
    FieldValueDeserialization: 2801,
    InvalidSiteIdPattern: 2803,
    SiteNotFound: 2804,
    UrlNotFound: 2805,

    // Url
    NoAccessToDeleteUrl: 2850,
    NoAccessToCreateUrl: 2851,
    NoAccessToEditUrl: 2852,
    NoAccessToViewUrl: 2853,

    // Tag
    TagParentNotFound: 2901,
    TagWithExternalIdAlreadyExists: 2902,
    TagWithAliasAlreadyExists: 2903,
    TagNotFound: 2904,
    TagValueIsRequired: 2905,
    TagPrentIdIsNotValid: 2906,
    TagIdOrAliasIsRequired: 2907,

  },
  GETWAY_AUTHORIZATION: {
    OrganizationIsNotValid: 1500,
    UserNotAuthorized: 1501,
  },
  INTEGRATION: {
    IntegrationsWithAliasExists: 4501,
    IntegrationWithAliasDoesNotExist: 4502,
    IntegrationDoesntAllowMultipleActivations: 4503,
    ProvideAValidActivation: 4504,
    NoAccessToListIntegrations: 4520,
    NoAccessToViewIntegration: 4521,
    NoAccessToCreateIntegration: 4522,
    NoAccessToEditIntegration: 4523,
    NoAccessToDeleteIntegration: 4524,
  },
  WEBHOOK: {
    NotFound: 4001,
    BadRequest: 4002,
    ValidationError: 4003,
    WebhookErrorCode: 4006,
    InvalidName: 4007,
    InvalidUrl: 4008,
    InvalidWebhookType: 4009,
    InvalidNumberOfAttempts: 4010,
    InvalidContentType: 4011,
    InvalidStatus: 4012,
    InvalidHttpMethod: 4013,
    InvalidEventTypes: 4014,
    HeaderKeyMissing: 4015,
    HeaderValueMissing: 4016,
    NotSupportedFilter: 4017,
    InvalidFilter: 4018,
    NonEmptyEntities: 4019,
    UniqueAuthorization: 4020,
    UniqueModelFilterRequired: 4021,
    ProhibitedModelFilter: 4022,
    ValidIdsAreRequired: 4023,
    ValidHeaderKeyRequired: 4024,
    NoAccessToCreateWebhook: 4025,
    NoAccesToEditWebhook: 4026,
    NoAccessToDeleteWebhook: 4027,
    NoAccessToViewWebhook: 4028,
    NoAccessToEditLogs: 4029,
    NoAccessToViewLogs: 4030,
    LimitOfAttemptsExceeded: 4031,
  },
})

<template>
  <div class="number-field">
    <b-form-group
      aria-required="true"
      :disabled="readOnly || !editable"
    >
      <template #label>
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
          :required="required"
        />
      </template>
      <div v-if="(showPredefinedValues && (!predefinedValues || !predefinedValues.length))">
        {{ $t('fields.codex-field-number.missing-predefined-values') }}
      </div>

      <template v-if="valueType === VALUE_TYPES.SINGLE">
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.INPUT">
          <b-form-input
            v-model.number="computedValue"
            type="number"
            @keydown="validateNumber"
          />
        </template>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN">
          <v-select
            v-model="computedValue"
            :options="predefinedValues"
            :clearable="!required"
          />
        </template>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.RADIO">
          <b-form-radio-group v-model="computedValue">
            <b-form-radio
              v-for="(num,idx) of predefinedValues"
              :key="idx"
              :value="num"
            >
              {{ num }}
            </b-form-radio>
          </b-form-radio-group>
        </template>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.RATING">
          <GjIcon
            v-for="(num,index) of starValues"
            :key="`${index}${value}`"
            :name="value >= index + 1 ? 'Star_fill' : 'Star'"
            size="50"
            @click.native="computedValue = index + 1"
          />
        </template>
      </template>

      <template v-else>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.INPUT">
          <b-form-tags
            v-model="computedValue"
            input-type="number"
            :placeholder="$t('fields.codex-field-number.add-a-number')"
          />
        </template>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.CHECKBOX">
          <b-form-checkbox-group
            v-model="computedValue"
            :options="predefinedValues"
            stacked
          />
        </template>
        <template v-if="appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN">
          <v-select
            v-model="computedValue"
            :options="predefinedValues"
            multiple
            no-close-on-backdrop
          />
        </template>
      </template>
      <FieldError :error="error" />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  VALUE_TYPES, RANGE_OPERATORS, NUMBER_FIELD_APPEARANCES, FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import { debounce, isNumber } from 'lodash'
import FieldName from '@/components/fields/FieldName.vue'
import NumberFilter from '@/components/filters-dropdown/filters/number/NumberFilter'
import NumberListFilter from '@/components/filters-dropdown/filters/numberList/NumberListFilter'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'
import { NUMBER_TYPES } from './index'

export default {
  name: 'NumberField',
  inject: ['filterValues', 'entryId'],
  components: {
    FieldError,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
  },
  data() {
    return {
      RANGE_OPERATORS,
      NUMBER_FIELD_APPEARANCES,
      VALUE_TYPES,
      setValue_: debounce(this.setValue, 500),
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'appearance',
      'predefinedValues',
      'alias',
      'name',
      'validation',
      'stars',
      'helpText',
      'helpTextDisplay',
      'type',
      'defaultValue',
    ]),
    computedValue: {
      get() {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          return this.value?.toString()
        }
        return this.value
      },
      set(v) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          this.setValue_(Number.isNaN(Number(v)) ? null : Number(v))
        } else if (v) {
          this.setValue_(v.map(e => (Number.isNaN(Number(e)) ? null : Number(e))))
        }
      },
    },
    starValues() {
      return this.widget.attrs.stars ? Array(this.widget.attrs.stars) : []
    },
    showPredefinedValues() {
      return this.appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN || this.appearance === NUMBER_FIELD_APPEARANCES.RADIO || this.appearance === NUMBER_FIELD_APPEARANCES.CHECKBOX || this.appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN
    },
  },
  beforeMount() {
    if (!this.validation.listRange) {
      this.$set(this.validation, 'listRange', {
        isEnabled: false,
        rangeOperator: RANGE_OPERATORS.BETWEEN,
        min: 2,
        max: 5,
        exactly: 1,
        errorMessage: 'List length should be: 2-5',
        errorTemplate: 'List length should be: {min}-{max}',
      })
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          if (NumberFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.computedValue = this.filterValues[this.alias].value1
          }
        } else if (this.appearance === NUMBER_FIELD_APPEARANCES.INPUT || (this.predefinedValues && this.predefinedValues.includes(this.filterValues[this.alias].value))) {
          if (NumberListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.computedValue = [this.filterValues[this.alias].value]
          }
        }
      }
      // \Prefill from filter
    }
  },
  methods: {
    setValue(value) {
      this.$emit('input', value)
    },
    validateLimits(num) {
      if (this.type === NUMBER_TYPES.INTEGER) {
        return num < Number(9007199254740991) && num > Number(-9007199254740991)
      } if (this.type === NUMBER_TYPES.DECIMAL) {
        return num < Number(1.7976931348623157E+308) && num > Number(-1.7976931348623157E+308)
      }
      return true
    },
    validateNumber(e) {
      if (this.type === NUMBER_TYPES.INTEGER && e.key === '.') {
        e.preventDefault()
      }
    },
    validate(value) {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        return this.validateSingle(value)
      }
      return this.validateList(value)
    },
    validateSingle(value) {
      if (this.required && !isNumber(value)) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (this.validateLimits(value) === false) {
        return { isValid: false, message: this.$t('fields.codex-field-number.validation.valid-number.error-message') }
      }

      if (this.validation.range.isEnabled && value != null && value != undefined) {
        if (this.validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN && (value < this.validation.range.min || value > this.validation.range.max)) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
        if (this.validation.range.rangeOperator === RANGE_OPERATORS.GTE && this.validation.range.min > value) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
        if (this.validation.range.rangeOperator === RANGE_OPERATORS.LTE && this.validation.range.max < value) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
      }
      if (this.type === NUMBER_TYPES.INTEGER && value && `${value}`.includes('.')) {
        return { isValid: false, message: this.$t('fields.codex-field-number.non-integer-error-message') }
      }
      return { isValid: true }
    },
    validateList(value) {
      const valueLength = value ? value.length : 0
      if (this.required && valueLength === 0) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (this.validation.listRange.isEnabled && valueLength > 0) {
        if (this.validation.listRange.rangeOperator === RANGE_OPERATORS.BETWEEN && (valueLength < this.validation.listRange.min || valueLength > this.validation.listRange.max)) {
          return { isValid: false, message: this.validation.listRange.errorMessage }
        }
        if (this.validation.listRange.rangeOperator === RANGE_OPERATORS.GTE && this.validation.listRange.min > valueLength) {
          return { isValid: false, message: this.validation.listRange.errorMessage }
        }
        if (this.validation.listRange.rangeOperator === RANGE_OPERATORS.LTE && this.validation.listRange.max < valueLength) {
          return { isValid: false, message: this.validation.listRange.errorMessage }
        }
        if (this.validation.listRange.rangeOperator === RANGE_OPERATORS.EXACTLY && this.validation.listRange.exactly != valueLength) {
          return { isValid: false, message: this.validation.listRange.errorMessage }
        }
      }

      if (this.validation.range.isEnabled) {
        const indexes = []
        for (let i = 0; i < value.length; i++) {
          const numberValue = value[i]
          if (this.validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN && (numberValue < this.validation.range.min || numberValue > this.validation.range.max)) {
            indexes.push(i)
          }
          if (this.validation.range.rangeOperator === RANGE_OPERATORS.GTE && this.validation.range.min > numberValue) {
            indexes.push(i)
          }
          if (this.validation.range.rangeOperator === RANGE_OPERATORS.LTE && this.validation.range.max < numberValue) {
            indexes.push(i)
          }
        }
        if (indexes.length > 0) {
          return { isValid: false, message: this.validation.range.errorMessage, indexes }
        }
      }

      const indexes = []
      for (let i = 0; i < value.length; i++) {
        if (this.validateLimits(value[i]) === false) {
          indexes.push(i)
        }
        if (indexes.length > 0) {
          return { isValid: false, message: this.$t('fields.codex-field-number.validation.valid-number.error-message'), indexes }
        }
      }

      if (this.type === NUMBER_TYPES.INTEGER && valueLength > 0) {
        for (let i = 0; i < value.length; i++) {
          if (`${value[i]}`.includes('.')) {
            return { isValid: false, message: this.$t('fields.codex-field-number.non-integer-error-message') }
          }
        }
      }
      return { isValid: true }
    },
  },
}
</script>

<style lang="scss" scoped>
.number-field__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>

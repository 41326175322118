import { render, staticRenderFns } from "./SectionsPopup.vue?vue&type=template&id=a50c46d6&scoped=true&"
import script from "./SectionsPopup.vue?vue&type=script&lang=js&"
export * from "./SectionsPopup.vue?vue&type=script&lang=js&"
import style0 from "./SectionsPopup.vue?vue&type=style&index=0&id=a50c46d6&prod&lang=scss&scoped=true&"
import style1 from "./SectionsPopup.vue?vue&type=style&index=1&id=a50c46d6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50c46d6",
  null
  
)

export default component.exports
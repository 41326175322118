import { toCamelize, toSlugV2 } from '@/utils/helpers'

export default {
  data() {
    return {
      error: false,
      tempAlias: null,
    }
  },
  computed: {
    nameState() {
      return this.validate()
    },
  },
  watch: {
    name(n, o) {
      if (!this.tempAlias || this.tempAlias === toCamelize(toSlugV2(o, ' '))) {
        this.tempAlias = toCamelize(toSlugV2(n, ' '))
      }
    },
  },
  methods: {
    validate() {
      return this.validateAlias(this.tempAlias, this.widget.id)
    },
    create(e) {
      const result = this.validate()
      if (result.isReserved || !result.isUnique || !result.isValid) {
        this.error = true
        e.preventDefault()
        return
      }
      this.alias = this.tempAlias
      this.error = false
      this.configured = true
    },
  },
}

<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-media-content.general.field-name')"
      label-for="at-media-field__name-input"
    >
      <b-form-input
        id="at-media-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-media-content.general.field-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('models.new-model.alias')"
      aria-required="true"
    >
      <b-form-input
        v-model.trim="tempAlias"
        :state="tempAlias === null ? null : !aliasState"
        trim
        :placeholder="$t('fields.codex-field-media-content.general.field-alias-placeholder')"
      />
      <b-form-invalid-feedback v-if="!nameState.isValid">
        {{ $t('fields.general.validation.alias-invalid') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="nameState.isReserved">
        {{ $t('fields.general.validation.alias-is-reserved') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!nameState.isUnique">
        {{ $t('fields.general.validation.alias-is-used') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-media-content.general.description')"
      label-for="number-field__description"
    >
      <b-form-textarea
        id="at-media-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-media-content.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-form-group :label="$t('fields.codex-field-media-content.general.number-of-media')">
      <b-form-radio
        v-model="valueType"
        :value="VALUE_TYPES.SINGLE"
      >
        {{ $t('fields.codex-field-media-content.general.one-media') }}
      </b-form-radio>
      <b-form-radio
        v-model="valueType"
        :value="VALUE_TYPES.LIST"
      >
        {{ $t('fields.codex-field-media-content.general.multiple-media') }}
      </b-form-radio>
    </b-form-group>
    <b-form-group :label="$t('fields.codex-field-media-content.general.media-type')">
      <b-form-checkbox
        v-model="validation.fileType.image"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.IMAGE) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="validation.fileType.files"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.FILE) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="validation.fileType.video"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.VIDEO) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="validation.fileType.playlists"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.VIDEO_PLAYLIST) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="validation.fileType.audio"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.AUDIO) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="validation.fileType.podcast"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.PODCAST) }}
      </b-form-checkbox>
    </b-form-group>
    <b-button
      variant="primary"
      class="w-100"
      :disabled="aliasState"
      @click="create"
    >
      {{ $t('fields.codex-field-media-content.general.create') }}
    </b-button>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import { ASSET_TYPES, mapAssetTypeLabel } from '@/codex-sdk/assets'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  data() {
    return {
      ASSET_TYPES,
      mapAssetTypeLabel,
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'description',
      'valueType',
      'configured',
      'validation',
    ]),
    aliasState() {
      return this.nameState.isReserved || !this.nameState.isUnique || !this.nameState.isValid
    },
  },
  watch: {
    'validation.fileType': {
      handler(v) {
        this.validation.fileType.isEnabled = v.image || v.files || v.video || v.playlists || v.audio || v.podcast
      },
      deep: true,
    },
  },
}
</script>

<template>
  <b-form-group class="author-field disable-in-quick-view">
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      />
    </template>
    <div class="entries-card authors-field__container entries-card">
      <div class="author-field__authors entries-list">
        <template v-if="authors">
          <draggable
            v-if="authors.length > 0"
            v-model="authors"
            class="entries-list__draggable"
            :group="{pull: false, push: false}"
            :disabled="readOnly || !editable"
          >
            <div
              v-for="(author, index) in authors"
              :key="author.id + index"
              class="entries-list__item"
            >
              <div class="entries-list__item-icon">
                <GjIcon
                  name="Drag"
                  size="24"
                />
                <b-avatar
                  :text="isCharLetter(getDisplayName(author))"
                  :src="author.image ? author.image.url : null"
                  variant="default"
                  class="author-field__avatar"
                />
              </div>
              <div class="entries-list__item-content">
                <div class="entries-list__item-name">
                  {{ getDisplayName(author) }}
                </div>
              </div>
              <div
                class="entries-list__item-edit"
                @click="editAuth(author)"
              >
                <GjIcon
                  name="Edit"
                  size="22"
                />
              </div>
              <div
                v-if="!readOnly && editable"
                class="entries-list__item-delete"
                @click="removeAuth(author.id)"
              >
                <GjIcon
                  name="Delete"
                  size="22"
                />
              </div>
            </div>
          </draggable>
          <div
            v-else
            class="entries-list__empty authors-select__empty"
          >
            <GjIcon name="AccountProfileUserCircle" />
            {{ $t('fields.codex-field-authors.render.no-authors') }}
          </div>
          <div class="authors-select__wrapper">
            <b-dropdown
              v-if="VALUE_TYPES.LIST === valueType || VALUE_TYPES.SINGLE && !computedValue"
              variant="link"
              no-caret
              :disabled="readOnly || !editable"
              @hide="search = ''"
            >
              <b-dropdown-header class="author-field__header">
                <GjIcon
                  v-if="!search"
                  name="Search"
                  style="fill:#A3B0C2;"
                  size="18"
                />
                <b-input
                  ref="searchAuthor"
                  v-model="search"
                  :placeholder="$t('fields.codex-field-authors.render.input-search-placeholder')"
                  class="author-field__dropdown-input"
                />
              </b-dropdown-header>
              <template #button-content>
                <div
                  class="entries-list__add authors-select__add hide-in-quick-view"
                >
                  <GjIcon
                    name="Plus"
                    size="20"
                  />
                  {{ $t('fields.codex-field-authors.render.add-new') }}
                </div>
              </template>
              <div
                ref="authorFieldMaindropdown"
                class="author-field__dropdown"
                @mousewheel="InfiniteLoadingObserver(`authorFieldMaindropdown`, 0)"
              >
                <b-dropdown-item
                  v-if="search.length > 0"
                  v-permission="['canCreateAuthors']"
                  class="dropdown__author"
                  @click="createAuthor"
                >
                  <b-avatar
                    :text="search[0]"
                    variant="default"
                    class="author-field__avatar"
                  />
                  <span class="author-field__name">
                    {{ search }}
                  </span>
                  <span class="author-dropdown__create-new">
                    <small>
                      {{ $t('fields.codex-field-authors.render.create-new') }}
                      <GjIcon
                        name="Plus_fill"
                        size="18"
                      />
                    </small>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(author, index) in authorSearch"
                  :key="author.id + index"
                  class="dropdown__author"
                  @click="checkAuth(author)"
                >
                  <b-avatar
                    :text="isCharLetter(getDisplayName(author))"
                    :src="author.image ? author.image.url : null"
                    variant="default"
                    class="author-field__avatar"
                  />
                  <span class="author-field__name">
                    {{ getDisplayName(author) }}
                  </span>
                  <span class="author-dropdown__status">
                    <GjIcon
                      v-if="authors.find(auth => auth.id === author.id)"
                      name="Check"
                      style="fill: #1D79F2;"
                    />
                    <small v-else>
                      {{ $t('fields.codex-field-authors.render.add') }}
                      <GjIcon
                        name="Plus_fill"
                        size="18"
                      />
                    </small>
                  </span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </template>
        <div
          v-else
          class="entries-list__loading"
        >
          <b-spinner
            variant="secondary"
            :label="$t('general.loading')"
            small
          />
          {{ $t('general.loading') }}
        </div>
      </div>
    </div>
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import draggable from 'vuedraggable'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { authorDefaults } from '@/codex-sdk/authors'
import { VALUE_TYPES, RANGE_OPERATORS, FIELD_FILTER_OPERATORS } from '@/views/models/constants'
import gql from 'graphql-tag'
import FieldName from '@/components/fields/FieldName.vue'
import store from '@/store'
import AuthorFilter from '@/components/filters-dropdown/filters/author/AuthorFilter'
import AuthorListFilter from '@/components/filters-dropdown/filters/authorList/AuthorListFilter'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  components: {
    FieldError,
    draggable,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
  },
  inject: ['showEditAuthorPopup', 'filterValues', 'entryId'],
  apollo: {
    authorsList: {
      query: gql`
        query Authors($query: String, $excludeAuthors: [String!], $offset: Int!, $limit: Int!) {
          authorCollection (limit: $limit, offset: $offset, where: {id: { notIn: $excludeAuthors }, or: [{byline: { contains: $query}}, {firstName: { contains: $query}}]} ) {
            items {
              id
              firstName
              lastName
              byline
              email
              image {
                url
              }
            }
            total
          }
        }
      `,
      update: data => data.authorCollection.items,
      result({ data }) {
        this.totalCount = data.authorCollection.total
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          authors_: [],
          offset: 0,
          limit: 10,
          query: this.search,
          excludeAuthors: this.authors.map(a => a.id),
        }
      },
    },
  },
  data() {
    return {
      authors_: [],
      userData: JSON.parse(localStorage.getItem('userData') || '{}'),
      totalCount: 0,
      VALUE_TYPES,
      RANGE_OPERATORS,
      search: '',
      size: 10,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'configured',
      'appearance',
      'validation',
      'valueType',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
    ]),
    authorSearch() {
      const authorsList = this.authorsList || []
      let returnable = [...this.authors, ...authorsList]
      if (this.search) {
        returnable = [...authorsList, ...this.authors]
      }
      return Array.from(new Set(returnable))
    },
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    authors: {
      get() {
        return this.authors_
      },
      set(v) {
        this.authors_ = v
        this.updateValue()
      },
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  async mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      let prefilledFromFilter = false
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          if (AuthorFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.authors = [this.authorFromFiltersToValue(this.filterValues[this.alias].value[0])]
            prefilledFromFilter = true
          }
        } else if (AuthorListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.authors = this.filterValues[this.alias].value.map(asset => this.authorFromFiltersToValue(asset))
          prefilledFromFilter = true
        }
      }
      // \Prefill from filter

      if (!prefilledFromFilter) {
        const author = await this.getMyAuthor()
        if (author) {
          this.authors_.push(author)
          this.updateValue()
        }
      }
    } else {
      await this.fetchAuthors()
    }
  },
  methods: {
    authorFromFiltersToValue(authorId) {
      const authorFromFiltersCache = store.state.filters.filtersCache[authorId] || {}

      return {
        ...authorFromFiltersCache,
      }
    },

    getDisplayName(author) {
      if (author.byline) return author.byline
      return `${author.firstName || ''} ${author.lastName || ''}`.trim()
    },
    async createAuthor() {
      if (this.readOnly || !this.editable) return
      const newAuth = authorDefaults()
      newAuth.byline = this.search
      const author = await this.$store.dispatch('authors/createAuthor', newAuth)
      if (author.data) {
        this.addAuth(author.data)
      }
    },
    checkAuth(author) {
      if (this.readOnly || !this.editable) return
      if (this.authors.find(auth => auth.id === author.id)) {
        this.authors = this.authors.filter(item => item.id !== author.id)
      } else {
        this.addAuth(author)
      }
    },
    async getMyAuthor() {
      try {
        return store.state.general.currentAuthor
      } catch (e) {
        console.log(e)
      }
      return null
    },
    updateValue() {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        this.computedValue = this.authors?.length ? { id: this.authors[0].id } : null
      } else if (this.authors) {
        this.computedValue = this.authors.map(e => ({ id: e.id }))
      }
    },
    isCharLetter(character) {
      const chars = []
      character.split('').forEach(char => {
        if ((/[a-zA-Z]/).test(char) && char.toLowerCase() != char.toUpperCase()) {
          chars.push(char)
        }
      })
      return chars[0]
    },
    InfiniteLoadingObserver(ref, dropdown) {
      let element
      if (dropdown) {
        element = this.$refs[ref][0]
      } else {
        element = this.$refs[ref]
      }
      if (Math.floor(element.scrollTop + 100 + element.clientHeight) > element.scrollHeight && this.authorsList.length < this.totalCount && !this.$apollo.loading) {
        this.$apollo.queries.authorsList.fetchMore({
          variables: {
            offset: this.authorsList.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev
            // this.authorsList = [...this.authorsList, ...fetchMoreResult.authorCollection.items]
            this.totalCount = fetchMoreResult.authorCollection.total
            return {
              authorCollection: {
                items: [...prev.authorCollection.items, ...fetchMoreResult.authorCollection.items],
                total: fetchMoreResult.authorCollection.total,
                __typename: 'CodexAuthorCollection',
              },
            }
          },
        })
      }
    },
    addAuth(author) {
      if (VALUE_TYPES.LIST === this.valueType) {
        const exists = this.authors.find(auth => auth.id === author.id)
        if (!exists) {
          this.authors = [...this.authors, author]
          this.search = ''
        }
      } else {
        this.authors = [author]
      }
    },
    async fetchAuthors() {
      try {
        let included = []
        if (Array.isArray(this.computedValue) && this.computedValue.length > 0) {
          included = this.computedValue.map(item => item.id)
        } else if (this.computedValue && this.computedValue.id) {
          included = [this.computedValue.id]
        }
        const { data } = await this.$apollo.query({
          query: gql`
          query Authors($authors: [String!]) {
            author: authorCollection (where: { id: { in: $authors } }) {
              items {
                id
                firstName
                lastName
                byline
                email
                image {
                  url
                }
              }
              total
            }
          }
        `,
          fetchPolicy: 'network-only',
          variables: {
            authors: [...included].filter(Boolean),
          },
        })

        if (this.valueType === VALUE_TYPES.LIST && this.computedValue) {
          const authors = []
          this.computedValue.forEach(a => {
            const author = data.author.items.find(e => e.id === a.id)

            if (author) {
              authors.push(author)
            } else {
              authors.push({
                id: a.id,
                byline: this.$t('fields.codex-field-authors.render.not-found'),
              })
            }
          })
          this.authors_ = authors
        } else if (this.computedValue) {
          let author = data.author.items.find(e => e.id === this.computedValue.id)

          if (!author) {
            author = {
              id: this.computedValue.id,
              byline: this.$t('fields.codex-field-authors.render.not-found'),
            }
          }

          this.authors_ = [author]
        }
      } catch (e) {
        console.log(e)
      }
    },
    async editAuth(author) {
      const auth = await this.showEditAuthorPopup({ author })
      if (auth) {
        this.authors = this.authors.map(item => {
          if (item.id === auth.id) {
            return auth
          }
          return item
        })
      }
    },
    removeAuth(id) {
      if (this.readOnly || !this.editable) return
      if (VALUE_TYPES.LIST === this.valueType) {
        this.authors = this.authors.filter(author => author.id !== id)
      } else {
        this.authors = []
      }
    },
    validate(value) {
      try {
        if (this.required) {
          if (VALUE_TYPES.SINGLE === this.valueType && !value) {
            return { isValid: false, message: this.validation.required.errorMessage }
          }
          if (value?.length < 1) {
            return { isValid: false, message: this.validation.required.errorMessage }
          }
        }
        if (this.validation.numberOfProperties.isEnabled) {
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.BETWEEN) {
            if (this.authors.length < this.validation.numberOfProperties.min || this.authors.length > this.validation.numberOfProperties.max) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.LTE) {
            if (this.authors.length > this.validation.numberOfProperties.max) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.GTE) {
            if (this.authors.length < this.validation.numberOfProperties.min) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
        }
      } catch (e) {
        return { isValid: false, message: this.validation.invalidAuth }
      }
      return { isValid: true, message: '' }
    },
  },
}

</script>

<style lang="scss">
.authors-select__wrapper {
  display: flex;
  justify-content: center;
}
.authors-select__add {
  gap: 8px !important;
}
.authors-select__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}
.dropdown.b-dropdown .dropdown-menu {
  z-index: 991;
}
</style>

<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
    :data-build-id="buildId"
  >
    <Popups>
      <component :is="layout">
        <router-view />
      </component>
    </Popups>

    <b-modal
      v-model="showInternetModal"
      ok-only
      :title="$t('popups.internetModal.title')"
      size="sm"
      centered
      :ok-title="$t('popups.internetModal.okButton')"
      modal-class="internet-modal"
    >
      <GjIcon name="Nointernet" />
      <p>
        {{ $t('popups.internetModal.checkInternet') }}
        <br>
        {{ $t('popups.internetModal.changesNotSave') }}
      </p>
    </b-modal>

    <portal-target
      name="charmap-portal"
      class="charmap"
    >
      <!-- Component portal target -->
    </portal-target>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import iconNames from '@gjirafatech/gjirafa-icons/icons.json'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
// eslint-disable-next-line import/named
import {
  setOidcErrorHook, setUserLoginHook, setUserLogoutHook, setUserRedirectHook,
} from '@/../gjirafa-front/script/auth/config'
import { Log } from 'oidc-client'
import { getUserData } from '@/auth/utils'
import Popups from './components/popups/Popups.vue'
import { setErrorsHandler, setSuccessHandler } from './codex-sdk/config'
import { setErrorsHandler as setPermissionsErrorHandler } from './codex-permissions/config'

if (false) {
  Log.logger = console
  Log.level = Log.DEBUG
}

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    ToastificationContent,
    Popups,

    // Layouts
    LayoutVertical,
    LayoutFull,
  },
  data() {
    return {
      showInternetModal: false,
      isOnline: true,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    buildId() {
      return process?.env?.VUE_APP_BUILD_ID || process?.env?.CF_PAGES_COMMIT_SHA || ''
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      // return this.$store.state.appConfig.layout.type
      return 'vertical'
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    // Codex Permissions
    setPermissionsErrorHandler((title, text) => {
      this.toastNotification({
        title,
        icon: 'Close',
        text,
        variant: 'danger',
      })
    })

    // Codex SDK
    setErrorsHandler((title, text) => {
      this.toastNotification({
        title,
        icon: 'Close',
        text,
        variant: 'danger',
      })
    })

    setSuccessHandler((title, text) => {
      this.toastNotification({
        title,
        icon: 'Check',
        text,
        variant: 'success',
      })
    })

    /*
      setUserLoginHook

      userDataObject
      --
      {
        id: 1,
        fullName: '',
        username: '',
        avatar: '',
        email: '',
        role: 'admin',
        ability: [{ action: 'manage', subject: 'all' }],
      }
    */
    // eslint-disable-next-line no-unused-vars
    setUserLoginHook(async (userDataObject, user, decodedAccessToken) => {
      // let { data } = await axios.get('./api/user')
      // userDataObject.id = data.id
      // userDataObject.avatar = data.profileImage
      if (this.$store.state.general?.currentUserPermissions && this.$store.state.general?.currentUserPermissions.length > 0) {
        userDataObject.ability = this.$store.state.general.currentUserPermissions
      }

      userDataObject.avatar = 'https://christian.gen.co/images/profile.square.small.jpg'
      return userDataObject
    })

    // setUserLogoutHook
    setUserLogoutHook(() => {
      //
      localStorage.removeItem('last-route')
    })

    setUserRedirectHook(url => {
      const invitationUrl = localStorage.getItem('redirect_invitation_url')
      if (invitationUrl) {
        setTimeout(() => {
          localStorage.removeItem('redirect_invitation_url')
        }, 1000)
        return invitationUrl
      }
      return url
    })

    setOidcErrorHook(() => { // payload
      // if (!payload || payload.context !== 'authenticateOidcSilent') { this.$router.push('/error-500') }
    })
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    createContextObject() {
      const context = {}

      Object.defineProperty(context, 'user', {
        enumerable: true,
        get: () => getUserData(),
      })

      Object.defineProperty(context, 'site', {
        enumerable: true,
        get: () => {
          const site = this.$store.state.general.currentSite

          return {
            id: site?.id,
            logo: site?.logo,
            name: site?.name,
            domain: site?.domain,
            description: site?.description,
            alias: site?.alias,
            attrs: site?.attrs,
          }
        },
      })

      Object.defineProperty(context, 'organization', {
        enumerable: true,
        get: () => {
          const organization = this.$store.state.general.currentOrganization

          return {
            id: organization?.id,
            logo: organization?.logo,
            name: organization?.name,
            alias: organization?.alias,
            languageCode: organization?.languageCode,
            activePlugins: organization.plugins.items.filter(plugin => plugin?.pluginVersion).map(plugin => ({
              ...plugin,
              path: `@/plugins/external/${plugin?.pluginName}/${plugin?.pluginVersion}/`,
            })) || []
            ,
          }
        },
      })

      Object.defineProperty(context, 'userPermissions', {
        enumerable: true,
        get: () => {
          const permissions = this.$store.state.general.currentUserPermissions

          const permissionsObj = permissions.reduce((obj, item) => {
            obj[item.action] = !item.inverted
            return obj
          }, {})

          return permissionsObj
        },
      })

      return context
    },
    updateOnlineStatus(e) {
      this.showInternetModal = e.type === 'offline'
    },
    toastNotification(props) {
      this.$toast({
        component: ToastificationContent,
        props,
      }, { position: 'bottom-left' })
    },
  },
  provide() {
    return {
      codexContext: this.createContextObject(),
      toastNotification: this.toastNotification,
      userCan: action => this.$can(action),
      doesIconExist(icon) {
        return iconNames.includes(icon)
      },
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 5000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
<style lang="scss">
.internet-modal {
  .modal-body {
    text-align: center;

    svg {
      background-color: #FCE9EA;
      color: #E34850;
      border-radius: 100px;
      padding: 8px;
      font-size: 20px;
      box-sizing: content-box;
      margin-top: 32px;
    }
    p {
      color: #052D61;
      font-size: 14px;
      margin: 16px 0 0 0;
    }
  }
}
</style>

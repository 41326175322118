import { merge } from 'lodash'
import i18n from '@/libs/i18n'

/**
 * Constants
 */

export const JOB_STATUSES = Object.freeze({
  UNDEFINED: 'UNDEFINED',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
})

export const RECURRING_JOB_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  SCHEDULED: 'SCHEDULED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  PAUSED: 'PAUSED',
})

export const JOB_ENTITY_TYPES = Object.freeze({
  RUNS: 'runs',
  RECURRING: 'recurring',
})

export const JOB_ENTITY_TYPE_INDEXES = Object.freeze([
  JOB_ENTITY_TYPES.RUNS,
  JOB_ENTITY_TYPES.RECURRING,
])

export const LOG_TYPES = Object.freeze({
  INFORMATION: 'INFORMATION',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
})

export const TASK_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
})

export const JOB_TYPES = Object.freeze({
  IMPORT_ASSETS: 'IMPORT_ASSETS',
  IMPORT_ENTRIES: 'IMPORT_ENTRIES',
  DELETE_CONTENT: 'DELETE_CONTENT',
  CREATE_ENVIRONMENT: 'CREATE_ENVIRONMENT',
  SYNC_ENVIRONMENTS: 'SYNC_ENVIRONMENTS',
  DELETE_ENVIRONMENT: 'DELETE_ENVIRONMENT',
  DELETE_FIELD: 'DELETE_FIELD',
  CLONE_SITE: 'CLONE_SITE',
})

export const DELETE_CONTENT_TASK_TYPES = Object.freeze({
  DELETE_ALL: 'DELETE_ALL',
  DELETE_ENTRIES: 'DELETE_ENTRIES',
  DELETE_TAGS: 'DELETE_TAGS',
  DELETE_URLS: 'DELETE_URLS',
})

/**
 * Helpers
 */
export function mapJobTypeLabel(type) {
  return i18n.t(`jobs.types.${type.toLocaleLowerCase()}`)
}

export function mapRecurringJobStatus(status) {
  return i18n.t(`jobs.recurring-job-statuses.${status?.toLocaleLowerCase()}`) || ''
}

export function mapJobStatus(status) {
  return i18n.t(`jobs.statuses.${status?.toLocaleLowerCase()}`) || ''
}

export function mapDeleteContentTaskType(type) {
  return i18n.t(`jobs.delete-content-task-types.${type?.toLocaleLowerCase()}`) || ''
}

export function mapLogTypeClass(type) {
  return `job-details__logs-editor-row--${type?.toLowerCase()}`
}

/**
 * Helpers
 */
export function jobDefaults(job = {}) {
  return merge({
    jobName: '',
    jobType: JOB_TYPES.IMPORT_ASSETS,
    fileKey: '',
    siteId: null,
    deleteContentTaskTypes: [],
    baseEnvironmentId: null,
    newEnvironmentDisplayName: '',
    newEnvironmentAlias: '',
    cronExpression: '',
    model: null,
    fieldAlias: null,
    isDeleteFieldJob: false,
    newSiteAlias: '',
    newSiteLogo: '',
    newSiteName: '',
    baseSiteId: '',
  }, job)
}

export function transformImportJobInput(job) {
  return {
    jobName: job.jobName,
    fileKey: job.fileKey,
    jobType: job.jobType,
  }
}

export function transformDeleteJobInput(job) {
  return {
    jobName: job.jobName,
    siteId: job.siteId,
    deleteContentTaskTypes: job.deleteContentTaskTypes,
    model: job.model,
    fieldAlias: job.fieldAlias,
    isDeleteFieldJob: job.jobType === JOB_TYPES.DELETE_FIELD,
  }
}

export function transformCreateEnvironmentJobInput(job) {
  return {
    jobName: job.jobName,
    baseEnvironmentId: job.baseEnvironmentId,
    newEnvironmentAlias: job.newEnvironmentAlias,
    newEnvironmentDisplayName: job.newEnvironmentDisplayName,
  }
}

export function transformDeleteEnvironmentJobInput(job) {
  return {
    jobName: job.jobName,
    environmentId: job.baseEnvironmentId,
  }
}

export function transformSyncEnvironmentsJobInput(job) {
  return {
    jobName: job.jobName,
    baseEnvironmentId: job.baseEnvironmentId,
    targetEnvironmentId: job.targetEnvironmentId,
    cronExpression: job.cronExpression,
  }
}

export function transformCreateCloneSiteJobInput(job) {
  return {
    baseSiteId: job.baseSiteId,
    jobName: job.jobName,
    newSiteAlias: job.newSiteAlias,
    newSiteLogo: job.newSiteLogo,
    newSiteName: job.newSiteName,
  }
}

export function getErrors(errors) {
  const errorsArray = []

  errors.forEach(error => {
    errorsArray.push(i18n.te(`sdk.error-codes.${error?.extensions?.code}`) ? i18n.t(`sdk.error-codes.${error?.extensions?.code}`, error?.extensions) : error.message)
  })
  return errorsArray.join('<br>')
}

export default class ActionExternalNotifications {
  constructor({
    Title = '',
    Body = '',
    IsMessageCustom = null,
    WebhookUrl = '',
    ButtonText = '',
  } = {}) {
    this.body = Body
    this.isMessageCustom = IsMessageCustom ?? !!Body
    this.title = Title
    this.webhookUrl = WebhookUrl
    this.buttonText = ButtonText
  }
}
